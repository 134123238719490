import React from 'react';

function AboutUs() {
  
    
return (
<>
    <div>
      <h1>About Us</h1>
      <h2>Welcome to our Paragon Sweps Official website! We are glad to have you here.</h2>
      <p> There is only one Paragon Sweeps account on facebook which is <a href="http://www.facebook.com/paragonsweeps"target="_blank" rel="noopener noreferrer">Paragon Sweeps</a> all other are pretend accounts.</p>
    </div>
  
</>);
}

export default AboutUs;
