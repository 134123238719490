import React from 'react';
import './App.css';// Make sure to import the CSS file

function Home() {
  return (
    <div className="form-container">
      <div className="form-box">
        <h2>Contact Us</h2>
        <form>
          <label>Name:</label>
          <input type="text" name="name" />

          <label>Phone Number:</label>
          <input type="text" name="phone" />

          <button type="submit">Submit</button>
        </form>
        <p> Please Input your Name and Phone Number so we can reach out to you for account creation</p>
      </div>
    </div>
  );
}

export default Home;

