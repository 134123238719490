import React from 'react';
import './Downloads.css'; // Make sure to create and import Downloads.css
import image1 from './images/image1.jpg'; 
import image2 from './images/image2.jpg'; 
import image3 from './images/image3.jpg'; 
import image4 from './images/image4.jpg'; 
import image5 from './images/image5.jpg'; 
import image6 from './images/image6.jpg'; 
import image7 from './images/image7.jpg'; 
import image8 from './images/image8.jpg'; 

function Downloads() {
  const links = [
    { name: 'FireKirin', url: 'http://web.firekirin.xyz/firekirin/firekirin/', img: image1},
    { name: 'Juwa', url: 'https://dl.juwa777.com/', img: image2},
    { name: 'GameVault', url: 'https://download.gamevault999.com/', img: image3},
    { name: 'UltraPanda', url: 'https://www.ultrapanda.mobi/', img: image4},
    { name: 'VegasSweeps', url: 'https://m.lasvegassweeps.com/', img: image5},
    { name: 'Vblink', url: 'https://www.vblink777.club/', img: image6},
    { name: 'Milkyway', url: 'https://milkywayapp.xyz/', img: image7},
    { name: 'CasinoIgnite', url: 'https://download.casinoignite.vip/', img: image8}
  ];


    
return (
<>
    <div className="downloads-container">
      {links.map((link, index) => (
        <div key={index} className="download-item">
          <img src={link.img} alt={link.name} className="download-image" />
          <div className="download-info">
            <h3>{link.name}</h3>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              <button>Download</button>
            </a>
          </div>
        </div>
      ))}
    </div>
  
</>);
}

export default Downloads;
