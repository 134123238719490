import React from 'react';
import { useLocation } from 'react-router-dom';

function SelectedItemDisplay() {
  const location = useLocation();
  const currentPath = location.pathname;

  let selectedItemText = 'Home';

  switch (currentPath) {
    case '/about-us':
      selectedItemText = 'About Us';
      break;
    case '/downloads':
      selectedItemText = 'Downloads';
      break;
    default:
      // Handle the default case here
      break;
  }

  return <div className="selected-item-display">{selectedItemText}</div>;
}

export default SelectedItemDisplay;
