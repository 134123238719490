import React, { useState } from 'react';
import { Route, Routes, Link } from 'react-router-dom'; // Removed unused Router import
import Home from './Home';
import AboutUs from './AboutUs';
import Downloads from './Downloads';
import SelectedItemDisplay from './SelectedItemDisplay'; // Import the new component
import './modern_style.css';
import logo from './logos.jpg';
import corner from './goldcorner.png';
import './App.css';

function App() {
  const currentYear = new Date().getFullYear();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
      <div className={`container ${menuOpen ? 'menu-open' : ''}`}>
        <img src={corner} className="corner-decoration corner-top-right" alt="Gold Corner Top Right" />
        <img src={corner} className="corner-decoration corner-bottom-left" alt="Gold Corner Bottom Left" />
        <header>
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <button className={`menu-button ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className={`menu-icon ${menuOpen ? 'open' : ''}`}></div>
            <div className={`menu-icon ${menuOpen ? 'open' : ''}`}></div>
            <div className={`menu-icon ${menuOpen ? 'open' : ''}`}></div>
          </button>
          <SelectedItemDisplay /> {/* Display the selected item to the left of the button */}
          {menuOpen && (
            <div className="menu-box">
              <nav>
                <ul>
                  <li>
                    <Link to="/" onClick={toggleMenu}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={toggleMenu}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/downloads" onClick={toggleMenu}>
                      Downloads
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/downloads" element={<Downloads />} />
        </Routes>
        <footer>
          <p>Copyright &copy; {currentYear} Paragon Sweeps</p>
        </footer>
      </div>
  );
}

export default App;
